import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import "./src/assets/scss/theme.scss";
import Header from "./src/components/Shared/Header";
import Footer from "./src/components/Shared/Footer";
import Chatbot from "./src/components/Shared/Chatbot";
import ReactGA from 'react-ga4';
import ConsentBanner from "./src/components/Shared/ConsentBanner";
import { useCookies } from 'react-cookie';

{/* ---- Import the current holiday here ---- */}

// import HolidayBanner from "./src/components/Shared/HolidayBanner";
// import { thanksgiving } from './src/constants/holidays';

const Layout = (props: { children: any; }) => {
 
  const [cookies] = useCookies(['gatsby-gdpr-google-analytics']);

  // Initialize ReactGA only on the client-side
  useEffect(() => {
    if (typeof window !== 'undefined' && cookies['gatsby-gdpr-google-analytics'] === 'true') {
      ReactGA.initialize('G-MRZ40PWVVX')
    }
  }, [cookies])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        {/*<Chatbot />*/}
        {/* ---- Add the current holiday banner data here ---- */}
        {/* <HolidayBanner data={thanksgiving} /> */}
        <Header/>
        <div className="main-content mk-content">{props.children}</div>
        <Footer />
      </div>
      <ConsentBanner />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
